.homeTextStyle {
    flex-wrap: wrap;
    margin-top: 21px;
    display: flex;
    justify-content: space-around;

    .titleStyle {
        margin: 8px 0;
        transition: opacity 3s;
        font-size: 1.9em;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        display: flex;
        gap: 10px;
        flex-grow: 1;
        justify-content: center;
        padding: 0 10px;
        flex-wrap: wrap;

        .titleText {
            color: #ffffff;
            background-color: #000000c7;
        }

        .boldText {
            color: #e48122;
            font-weight: bold;
        }
    }
}



@media screen and (max-width: 998px) {
    #homeText {
        width: 100vw;
        background: linear-gradient(rgba(0, 0, 0, 0.50) 93%, rgba(0, 0, 0, 0.05));
        margin-top: 0;
    }
}

@media screen and (max-width: 343px) {
    #homeText {
        margin-left: calc((343px - 100vw) /2 * -1);
        font-size: calc(100vw * .07);
    }
}