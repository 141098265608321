.bluePanel {
    color: white;
    width: 87vw;
    min-width: 260px;
    margin-Left: auto;
    margin-Right: auto;
    position: relative;
    padding: 20px;
    font-Size: 1em;
    line-Height: 150%;
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.95), rgba(0, 92, 138, 0.95), rgba(0, 0, 0, 0.95));
    border: 4px solid rgb(0, 31, 84);
    border-Radius: 5px;
    text-Shadow: 2px 2px 3px #004669, 2px 2px 5px #000000;
    min-Height: 178px;
    transition: top 750ms ease-in;
}

.bluePanel:after {
    content: '';
    position: absolute;
    left: -3px;
    top: -3px;
    right: -3px;
    bottom: -3px;
    border: 1px solid rgb(39, 186, 233);
    border-radius: 5px;
    box-shadow: inset 0 0 2px 1px rgb(39, 186, 233), 0 0 3px 0 rgb(39, 186, 233);
    pointer-events: none;
}