@import '../../../_universal.scss';

.subHeader {
	color: $hover-orange;
	font-size: 0.875em;
	font-weight: bold;
	letter-spacing: 0.1em;
	margin: 0 0 2px 0;
}

.subHeaderExtend {
	@extend .subHeader;
	margin-top: 25px;
}

.content {
	line-height: 150%;
	margin: 0;
}

.subImage {
	flex: 1 1;
	padding: 0 10px 0 0;
	text-align: center;
	opacity: 0.5;
	transition: opacity 100ms linear;
	cursor: pointer;
}

.subImage:hover {
	opacity: 1;
}

.subImageSelected {
	flex: 1 1;
	padding: 0 10px 0 0;
	text-align: center;
	opacity: 1;
	cursor: pointer;
}

.modal {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.765);

	.modalClick {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.modalHeader {
		position: absolute;
		z-index: 5;
		text-align: right;
		float: right;
		width: 100%;

		.modalCloseBtn {
			color: #ffffff;
			background-color: rgba(0, 0, 0, 0);
			border: none;
			border-radius: 2px;
			cursor: pointer;
			width: 25px;
			height: 25px;
			font-size: 1em;
			font-weight: 600;
			text-align: center;
			padding: 0px;
		}
	}

	.modalImageContainer {
		display: block;
		height: 95%;
		overflow-x: auto;
		text-align: center;
		position: absolute;
		width: 100%;
		top: 30px;

		.modalImage {
			box-shadow: rgb(0, 0, 0) 2px 2px 5px;
			height: 95%;
			position: relative;
			z-index: 1;
		}
	}
}

/*portPicture1*/
@media screen and (max-width: 735px) {
	#portPicture1 {
		flex-basis: 100% !important;
	}

	#portInformation {
		flex-basis: 100% !important;
	}

	.subImage {
		flex-basis: 25% !important;
	}

	.subImageSelected {
		flex-basis: 25% !important;
	}
}

@media screen and (min-width: 735px) {
	#portPicture1 {
		flex-basis: 0 !important;
	}

	#portInformation {
		flex-basis: 0 !important;
	}

	.subImage {
		flex-basis: 0 !important;
	}

	.subImageSelected {
		flex-basis: 0 !important;
	}
}
