.fade-enter {
    opacity: 0;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 2s ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 2s ease-in;
}

#pageContainer {
    position: absolute;
    z-index: 2;
    width: 100vw;
}