#background {
    position: relative;
    height: calc(100vw - 94vh);
    min-height: calc(100vh - 145px);
    /*716px*/
    display: flex;
    overflow: hidden;
}

#space {
    position: relative;
}

#cityContainer {
    position: relative;
    min-height: 633px;
    min-width: 1102px;
    width: 100vw;
    border-bottom: 2px solid rgb(39, 186, 233);
    align-self: flex-end;
}

#city {
    position: relative;
    min-height: 633px;
    min-width: 1102px;
    width: 100vw;
    filter: brightness(1.25) contrast(1.1);
}

#signFlash {
    background: black;
    width: 75px;
    height: 23px;
    position: absolute;
    left: 395px;
    top: 310px;
    opacity: 0;
    animation-name: signFlashing;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-delay: 5s;
}

@keyframes signFlashing {
    0% {
        opacity: 0
    }

    72.8% {
        opacity: 0
    }

    73% {
        opacity: 75
    }

    73.2% {
        opacity: 0
    }

    73.8% {
        opacity: 0
    }

    74% {
        opacity: 100
    }

    74.2% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

#towerFlash {
    background: #ffffff;
    width: 5px;
    height: 5px;
    left: 310px;
    position: absolute;
    top: 118px;
    animation-name: towerFlashing;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    border-radius: 5px;
    filter: blur(1px);
    rotate: 45deg
}

#towerFlash:after {
    background: #ffdcb0;
    width: 30px;
    height: 30px;
    top: -10px;
    left: -7px;
    position: absolute;
    display: block;
    content: "";
    border-radius: 5px;
    filter: blur(15px);
    z-index: -1;
}

@keyframes towerFlashing {
    0% {
        opacity: 0
    }

    65% {
        opacity: 0
    }

    75% {
        opacity: 100
    }

    85% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

/* 1030px and smaller */
@media screen and (max-width: 1030px) {
    #background {
        margin-left: calc((1030px - 100vw) /2 * -1);
    }
}

/* 1104 pixels or less */
@media screen and (max-width: 1104px) {
    #towerFlash {
        left: 317px;
        top: 121px;
        width: 5px;
        height: 5px;
        border-radius: 0;
        filter: blur(1px);
    }

    #towerFlash:after {
        width: 30px;
        height: 30px;
        left: -12px;
        top: -12px;
    }
}

/* 1105 pixels or more */
@media screen and (min-width: 1105px) {
    #towerFlash {
        left: calc((100vw - 1105px) / 3.52 + 316px);
        top: calc((100vw - 1105px) / 8.9 + 122px);
        width: calc((100vw - 1105px) * .01 + 5px);
        height: calc((100vw - 1105px) * .01 + 5px);
    }

    #towerFlash:after {
        width: calc((100vw - 1105px) * .01 + 30px);
        height: calc((100vw - 1105px) * .01 + 30px);
        top: -13px;
        left: -13px;
    }

    #signFlash {
        left: calc((100vw - 1105px) / 2.78 + 395px);
        top: calc((100vw - 1105px) / 3.5 + 310px);
        width: calc((100vw - 1105px) * .07 + 75px);
        height: calc((100vw - 1105px) * .03 + 23px);
    }
}

/* @media screen and (min-width: 1335px) {
    #background {
        height: calc((97vw - 70vh));
    }
} */