@import '../../universal';

.pageWrapper {
	height: calc(100vh - 96px);
}

.sections {
	font-size: 0.9em;
	text-decoration: none;
	letter-spacing: 1px;
	cursor: pointer;
	margin: 0 0 16px 0;
	transition: 0.2s;
	text-transform: uppercase;
	font-weight: bold;
}

.sections:hover {
	color: #848484;
}

.selectedSection {
	color: $hover-orange;
	font-size: 0.9em;
	text-decoration: none;
	letter-spacing: 1px;
	cursor: pointer;
	margin: 0 0 16px 0;
	text-transform: uppercase;
	font-weight: bold;
}

.sectionHeading {
	color: $hover-orange;
	font-size: 1em;
	font-weight: bold;
	text-transform: uppercase;
}

.subSections {
	font-size: 1em;
	text-decoration: none;
	letter-spacing: 2px;
	cursor: pointer;
	margin: 0;
	text-shadow: none;
	text-transform: uppercase;
}

.subSections:hover {
	color: $hover-orange;
}

.gridColumns {
	display: flex;
	flex-wrap: nowrap;
	height: 100%;
}

.email {
	color: #ffffff;
}

.email:hover {
	color: $hover-orange;
}

.bluePanelScroll {
	max-height: calc(100vh - 165px);
	height: 88%;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
	width: 8px;
}

::-webkit-scrollbar:horizontal {
	height: 8px;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(39, 186, 233);
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
}
